import React from 'react';
import { graphql } from 'gatsby';

import { HowItWorksPageTemplate } from '../templates';
import { Layout } from '../components';

const HowItWorksPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    return (
        <Layout title={ frontmatter.headerTitle }>
            <HowItWorksPageTemplate { ...frontmatter }/>
        </Layout>
    );
};

export default HowItWorksPage;

export const pageQuery = graphql`
    query HowItWorksPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "how-it-works-page" } }) {
            frontmatter {
                headerImage {
                    cloudinaryMedia
                    assetType
                    deliveryType
                    urlRemainder
                    sourceURL
                }
                headerTitle
                heroTitle
                heroBody
            }
        }
    }
`;